import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { first } from 'rxjs/operators'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconsB from '@fortawesome/free-brands-svg-icons'
import { ApiService } from '../api.service'


@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.styl']
})
export class ProjectComponent implements OnInit {
  private slug: any = undefined
  public project: any = {}
  public icons = icons
  public iconsB = iconsB
  public btnLang = 'View'
  public imageGallery: Array<object> = []
  public imageSize = { width: '100%', height: '30%', space: 1 }

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.route.params.pipe(
      first()
    ).subscribe({
      next: (x) => {
        this.slug = x.projectSlug
      }
    })
  }

  updateMeta(): void {
    this.titleService.setTitle(`${this.project.title} - Gareth Johnstone`)

    this.metaService.updateTag({ property: 'og:title', content: `${this.project.title} - Gareth Johnstone` })
    this.metaService.updateTag({ property: 'og:description', content: this.project.summary })
    this.metaService.updateTag({ property: 'og:image', content: this.project.image })
    this.metaService.updateTag({ property: 'og:url', content: window.location.toString() })
    this.metaService.updateTag({ property: 'og:image:alt', content: `${this.project.title} - Gareth Johnstone` })

    this.metaService.updateTag({ name: 'twitter:title', content: `${this.project.title} - Gareth Johnstone` })
    this.metaService.updateTag({ name: 'twitter:description', content: this.project.summary })
    this.metaService.updateTag({ name: 'twitter:image', content: 'https://garethjohnstone.co.uk' + this.project.image })
    this.metaService.updateTag({ name: 'twitter:url', content: window.location.toString() })
    this.metaService.updateTag({ name: 'twitter:image:alt', content: `${this.project.title} - Gareth Johnstone` })
  }

  ngOnInit(): void {
    this.api.post('project', { projectSlug: this.slug }).subscribe({
      next: (x) => {
        this.project = x.db
      },
      complete: () => {
        this.updateMeta()
        if (this.project.website) {
          if (this.project.website.includes('github.com')) {
            this.btnLang = `View on Github`
          } else if (this.project.website.includes('chrome.google.com')) {
            this.btnLang = `View on Chrome Store`
          } else if (this.project.website.includes('play.google.com')) {
            this.btnLang = `View on Google Play`
          } else {
            this.btnLang = `View Website`
          }
        }

        this.imageGallery.push({
          image: this.project.image,
          thumbImage: this.project.image,
          title: false,
          alt: this.project.title,
        })
        this.project['image-gallery'].forEach((img: string) => {
          this.imageGallery.push({
            image: img,
            thumbImage: img,
            title: false,
            alt: this.project.title,
          })
        })
      },
      error: () => {
        this.router.navigate(['offline'])
      }
    })
  }
}
