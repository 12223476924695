import { Component } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { ApiService } from '../api.service'

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.styl']
})
export class PortfolioComponent {
  public projects: Array<any> = []
  public icons = icons

  constructor(
    private api: ApiService
  ) {
    this.api.fetch('/projects').subscribe({
      next: (data: any) => {
        this.projects = data.db
      }
    })
  }
}
