<section class="cta-section theme-bg-light py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading">Well, this sucks...</h2>
        <div class="intro">
            <p>My website appears to be not connecting to its database.</p>
            <p>Likely causes are:</p>

            <p>😱 The MongoDB "Free Tier" servers are a bit busy.</p>
            <p>😱 I've been making changes late at night and probably made a mitsake somehwere.</p>

            <p>My socials are below if you want to let me know or look at anything I've done 😳</p>
            <ul class="list-inline mb-0">
                <li class="list-inline-item mb-3">
                    <a class="twitter" href="https://twitter.com/gazajohnstone" target=_blank>
                        <fa-icon [icon]='iconsB.faTwitter' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>

                <li class="list-inline-item mb-3">
                    <a class="linkedin" href="https://www.linkedin.com/in/gazajohnstone/" target=_blank>
                        <fa-icon [icon]='iconsB.faLinkedin' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>
                <li class="list-inline-item mb-3">
                    <a class="github" href="https://github.com/gaza1994" target=_blank>
                        <fa-icon [icon]='iconsB.faGithub' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>
            </ul>
            <!--//social-list-->

        </div>
        <!--//container-->
    </div>
</section>