<div id="navigation" class="collapse navbar-collapse flex-column">
    <div class="profile-section pt-3 pt-lg-0">
        <img class="profile-image mb-3 rounded-circle mx-auto" src="/assets/images/profile.webp" alt="image" loading="lazy">

        <div class="bio mb-3">
            Hi, my name is Gareth Johnstone and I'm a web developer.
            Welcome to my portfolio!
        </div>
        <!--//bio-->
        <ul class="social-list list-inline py-2 mx-auto">
            <li class="list-inline-item">
                <a href="https://www.linkedin.com/in/gazajohnstone" target=_blank>
                    <fa-icon [icon]='iconsB.faLinkedin' [fixedWidth]='true'></fa-icon>
                </a>
            </li>
            <li class="list-inline-item">
                <a href="https://github.com/gaza1994" target=_blank>
                    <fa-icon [icon]='iconsB.faGithub' [fixedWidth]='true'></fa-icon>
                </a>
            </li>
            <li class="list-inline-item">
                <a href="https://stackoverflow.com/users/3662851/gaza" target=_blank>
                    <fa-icon [icon]='iconsB.faStackOverflow' [fixedWidth]='true'></fa-icon>
                </a>
            </li>
            <li class="list-inline-item">
                <a href="https://codepen.io/gaza1994" target=_blank>
                    <fa-icon [icon]='iconsB.faCodepen' [fixedWidth]='true'></fa-icon>
                </a>
            </li>
        </ul>
        <!--//social-list-->
        <hr>
    </div>
    <!--//profile-section-->

    <ul class="navbar-nav flex-column text-left">

        <li class="nav-item mobile-only" [routerLinkActiveOptions]= "{exact: false}" routerLinkActive="active" *ngFor="let m of menuItems">
            <a class="nav-link" [routerLink]="[m.url]" data-toggle="collapse" data-target="#navigation">
                <fa-icon [icon]='m.icon' [fixedWidth]='true'></fa-icon>
                {{m.title}}
            </a>
        </li>

        <li class="nav-item desktop-only" routerLinkActive="active" *ngFor="let m of menuItems">
            <a class="nav-link" [routerLink]="[m.url]">
                <fa-icon [icon]='m.icon' [fixedWidth]='true'></fa-icon>
                {{m.title}}
            </a>
        </li>

    </ul>
</div>