<section class="cta-section theme-bg-light py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading">4 ohhhh <span title='insert swear word here...'>****</span> 4</h2>
        <div class="intro">
            <p>One of many things has happend here....</p>

            <p>You've spelled something wrong in the URL</p>
            <p>I've spelled something wrong and you've spelled something right 😂</p>
            <p>You thought this page existed... it doesnt</p>

            <p>Regardless, nothing exists here.</p>
            <p><button class="btn btn-primary" [routerLink]="['/home']">Go Home</button></p>
            <ul class="list-inline mb-0">
                <li class="list-inline-item mb-3">
                    <a class="twitter" href="https://twitter.com/gazajohnstone" target=_blank>
                        <fa-icon [icon]='iconsB.faTwitter' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>

                <li class="list-inline-item mb-3">
                    <a class="linkedin" href="https://www.linkedin.com/in/gazajohnstone/" target=_blank>
                        <fa-icon [icon]='iconsB.faLinkedin' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>
                <li class="list-inline-item mb-3">
                    <a class="github" href="https://github.com/gaza1994" target=_blank>
                        <fa-icon [icon]='iconsB.faGithub' [size]="'lg'" [fixedWidth]='true'></fa-icon>
                    </a>
                </li>
            </ul>
            <!--//social-list-->

        </div>
        <!--//container-->
    </div>
</section>