import { Component } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import Swal from 'sweetalert2'
import { ApiService } from '../api.service'
import * as icons from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-resume-request-access',
  templateUrl: './resume-request-access.component.html',
  styleUrls: ['./resume-request-access.component.styl']
})
export class ResumeRequestAccessComponent {
  public cvAccessForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    company: new FormControl(''),
    message: new FormControl('')
  })
  public icons = icons
  public loading = false

  constructor(
    private api: ApiService,
    private $gaService: GoogleAnalyticsService
  ) { }

  get f(): any { return this.cvAccessForm.controls }

  submit(): any {
    if (!this.cvAccessForm.valid) {
      return Swal.fire({
        title: 'Form Invalid',
        text: 'All fields are required',
        icon: 'error'
      })
    }

    this.loading = true

    const form = {
      name: this.f.name.value,
      email: this.f.email.value,
      company: this.f.company.value,
      message: this.f.message.value
    }

    this.api.post('cvaccessrequest', { formPost: form }).subscribe({
      next: (x) => {
        if (x.db) {
          this.cvAccessForm.reset()
          this.$gaService.event('submit', 'cvAccessForm', 'success')
          Swal.fire('Thanks', 'I will be in touch', 'success')
        } else {
          this.$gaService.event('submit', 'cvAccessForm', 'fail')
          Swal.fire('oops', 'Something went wrong :( - I have been informed of this error.', 'error')
        }
      },
      complete: () => {
        this.loading = false
      }
    })
  }
}
