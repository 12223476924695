import { NgModule } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HttpConfigInterceptor } from './httpconfig.interceptor'

import { AppRoutingModule } from './app-routing.module'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgImageSliderModule } from 'ng-image-slider'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics'

import { AppComponent } from './app.component'
import { HeaderComponent } from './header/header.component'
import { MenuComponent } from './menu/menu.component'
import { FooterComponent } from './footer/footer.component'
import { HomeComponent } from './home/home.component'
import { AboutMeSectionComponent } from './home/about-me-section/about-me-section.component'
import { OverviewSectionComponent } from './home/overview-section/overview-section.component'
import { SpacerComponent } from './spacer/spacer.component'
import { FeaturedSectionComponent } from './home/featured-section/featured-section.component'
import { PortfolioComponent } from './portfolio/portfolio.component'
import { ProjectComponent } from './project/project.component'
import { ResumeComponent } from './resume/resume.component'
import { ResumeRequestAccessComponent } from './resume-request-access/resume-request-access.component'
import { OfflineComponent } from './offline/offline.component'
import { NotFoundComponent } from './not-found/not-found.component'
import { LoadingComponent } from './loading/loading.component'
import { LegalComponent } from './legal/legal.component'
import { ContactComponent } from './contact/contact.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    AboutMeSectionComponent,
    OverviewSectionComponent,
    SpacerComponent,
    FeaturedSectionComponent,
    PortfolioComponent,
    ProjectComponent,
    ResumeComponent,
    ResumeRequestAccessComponent,
    OfflineComponent,
    NotFoundComponent,
    LoadingComponent,
    LegalComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    NgxGoogleAnalyticsModule.forRoot('UA-148136743-1'),
    NgxGoogleAnalyticsRouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    // LightboxModule,
    NgImageSliderModule
  ],
  providers: [
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  bootstrap: [
    AppComponent,
  ],
  exports: [ ]
})
export class AppModule { }
