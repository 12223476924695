<div class="row">
    <div class="col-md-6 mb-5" *ngFor="let i of [].constructor(4)">
        <div class="card project-card is-loading">
            <div class="row no-gutters">
                <div class="col-lg-4 card-img-holder">
                    <div class="image"></div>
                </div>
                <div class="col-lg-8">
                    <div class="card-body">
                        <p class="card-text"></p>
                        <p class="card-text"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>