<section class="cta-section theme-bg-light py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading">Portfolio</h2>
        <div class="intro">
            <p>Welcome to my portfolio.</p>
        </div>
    </div>
    <!--//container-->
</section>
<section class="projects-list px-3 py-5 p-md-5">
    <div class="container">

        <loading-portfolio *ngIf="projects.length < 1"></loading-portfolio>


        <div class="project-cards row isotope">
            <div class="isotope-item col-md-6 mb-5" *ngFor="let project of projects">
                <div class="card project-card">
                    <div class="row no-gutters">
                        <div class="col-lg-4 card-img-holder">
                            <img [src]="project.image" class="card-img" alt="image" loading="lazy">
                        </div>
                        <div class="col-lg-8">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <a [routerLink]="['/portfolio/'+project.slug]" class="theme-link">
                                        {{project.title}}
                                    </a>
                                </h5>
                                <p class="card-text" [innerHTML]="project.summary"></p>
                                <p class="card-text" *ngIf="project.client">
                                    <small class="text-muted">Client: {{project.client}}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="link-mask">
                        <a class="link-mask-link" [routerLink]="['/portfolio/'+project.slug]"></a>
                        <div class="link-mask-text">
                            <a class="btn btn-secondary" [routerLink]="['/portfolio/'+project.slug]">
                                <fa-icon [icon]='icons.faEye' [fixedWidth]='true'></fa-icon>
                                View Project
                            </a>
                        </div>
                    </div>
                    <!--//link-mask-->
                </div>
                <!--//card-->
            </div>
            <!--//col-->

        </div>
        <!--//row-->

    </div>
</section>