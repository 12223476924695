<section class="cta-section theme-bg-light py-5">
    <div class="container text-center single-col-max-width">
        <h2 class="heading">Request Access to my CV</h2>
        <div class="intro">
            <p>
                If you would like a copy of my CV, please use the form below to request access to it.
            </p>
        </div>
    </div>
    <!--//container-->
</section>
<section class="contact-section px-3 py-5 p-md-5">
    <div class="container">
        <form id="contact-form" class="contact-form col-lg-8 mx-lg-auto" [formGroup]="cvAccessForm" (ngSubmit)="submit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label class="sr-only" for="cname">Name</label>
                    <input type="text" class="form-control" id="cname" name="name" placeholder="Name*" minlength="2"
                        required="" aria-required="true" [formControlName]="'name'">
                </div>
                <div class="form-group col-md-6">
                    <label class="sr-only" for="cemail">Business Email</label>
                    <input type="email" class="form-control" id="cemail" name="email" placeholder="Email*" required=""
                        aria-required="true" [formControlName]="'email'">
                </div>
                <div class="form-group col-12">
                    <label class="sr-only" for="cname">Company</label>
                    <input type="text" class="form-control" id="cname" name="company" placeholder="Company*" minlength="2"
                        required="" aria-required="true" [formControlName]="'company'">
                </div>
                <div class="form-group col-12">
                    <label class="sr-only" for="cmessage">Your message</label>
                    <textarea class="form-control" id="cmessage" name="message" placeholder="Enter your message*"
                        rows="10" required="" aria-required="true" [formControlName]="'message'"></textarea>
                </div>
                <div class="form-group col-12">
                    <button type="submit" class="btn btn-block btn-primary py-2">Request</button>
                </div>
            </div>
            <!--//form-row-->
        </form>
    </div>
    <!--//container-->
</section>