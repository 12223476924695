import { Component, OnInit } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-about-me-section',
  templateUrl: './about-me-section.component.html',
  styleUrls: ['./about-me-section.component.styl']
})
export class AboutMeSectionComponent implements OnInit {
  public icons = icons

  constructor() { }

  ngOnInit(): void {
  }

}
