<section *ngIf="!project.title" class='text-center'>
    <fa-icon class='mr-2' [icon]='icons.faSpinner' [size]='"4x"' [spin]='true' [fixedWidth]='true'></fa-icon>
</section>

<section *ngIf="project.title" class="cta-section theme-bg-light py-5">
    <div class="container single-col-max-width">
        <h2 class="heading text-center">Project: {{project.title}}</h2>
        <div class="project-intro text-center">
            <p class="mb-0 lead" [innerHTML]='project.summary'></p>
        </div>

    </div>
    <!--//container-->
</section>

<section *ngIf="project.title" class="project px-3 py-5 p-md-5">
    <div class="container">
        <div class="project-meta media flex-column flex-md-row p-4 theme-bg-light">
            <ng-image-slider 
                class="project-thumb mb-3 mb-md-0 mr-md-5 rounded d-md-inline-block" 
                [images]="imageGallery" 
                [imageSize]='imageSize' 
                [manageImageRatio]='true'
                [infinite]='true'
                [autoSlide]='{interval: 5, stopOnHover: true}'
                [showArrow]='false'
                [lazyLoading]='true'
                #nav></ng-image-slider>
                
            <div class="media-body">
                <div class="client-info">
                    <h3 class="client-name font-weight-bold mb-4">{{project.title}}</h3>
                    <ul class="client-meta list-unstyled">
                        <li class="mb-2">
                            <fa-icon class='mr-2' [icon]='icons.faCogs' [fixedWidth]='true'></fa-icon>
                            <strong>Project Type: </strong>
                            <span style='text-transform: capitalize'>{{project.type}}</span>
                        </li>
                        <li class="mb-2">
                            <fa-icon class='mr-2' [icon]='icons.faCode' [fixedWidth]='true'></fa-icon>
                            <strong>Languages: </strong>
                            <div class="row section-intro">
                                <div class="item col-6 col-sm-2" *ngFor="let lang of project.languages">
                                    <div class="item-inner">
                                        <div class="item-icon">
                                            <fa-icon *ngIf="lang === 'javascript'" [icon]='iconsB.faJsSquare'
                                                [fixedWidth]='true'></fa-icon>
                                            <fa-icon *ngIf="lang === 'html'" [icon]='iconsB.faHtml5'
                                                [fixedWidth]='true'></fa-icon>
                                            <fa-icon *ngIf="lang === 'css'" [icon]='iconsB.faCss3' [fixedWidth]='true'>
                                            </fa-icon>
                                            <fa-icon *ngIf="lang === 'php'" [icon]='iconsB.faPhp' [fixedWidth]='true'>
                                            </fa-icon>
                                            <fa-icon *ngIf="lang === 'java'" [icon]='iconsB.faJava' [fixedWidth]='true'> </fa-icon>
                                            <fa-icon *ngIf="lang === 'wordpress'" [icon]='iconsB.faWordpress' [fixedWidth]='true'> </fa-icon>
                                            <fa-icon *ngIf="lang === 'bootstrap'" [icon]='icons.faCode' [fixedWidth]='true'> </fa-icon>
                                        </div>
                                        <h6 class="item-title">{{lang}}</h6>
                                        <div class="item-desc">

                                        </div>
                                    </div>
                                    <!--//item-inner-->
                                </div>
                            </div>
                            {{project.langauges}}
                        </li>
                    </ul>
                    <div class="client-bio mb-4">
                        <div class="text-center">
                            <a class="btn btn-primary" [href]="project.website" target="_blank" *ngIf="project.website">
                                <fa-icon [icon]='icons.faPaperPlane' [fixedWidth]='true'></fa-icon>
                                {{btnLang}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--//media-body-->
        </div>
        <!--//project-meta-->

        <div class="project-sections py-5">
            <div class="project-section mb-5" [innerHTML]="project.html">

            </div>
        </div>
    </div>
</section>