<section class="about-me-section p-3 p-lg-5 theme-bg-light">
    <div class="container">
        <div class="profile-teaser media flex-column flex-lg-row">
            <div class="media-body">
                <h2 class="name font-weight-bold mb-1">Gareth Johnstone</h2>
                <div class="tagline mb-3">Web Developer</div>
                <div class="bio mb-4">
                    <p>I'm a web developer specialised in frontend and backend development, I have written many web apps, websites, apps and little projects over the years.</p>
                    <p>This website has been built to showcase them.</p>
                </div>
                <!--//bio-->
                <div class="mb-4">
                    <a class="btn btn-primary mr-2 mb-3" [routerLink]="['/portfolio']">
                        <fa-icon [icon]='icons.faArrowAltCircleRight' [fixedWidth]='true'></fa-icon>
                        <span class="d-none d-md-inline">View</span>
                        Portfolio
                    </a>
                    <a class="btn btn-secondary mb-3" [routerLink]="['/resume']">
                        <fa-icon [icon]='icons.faFileAlt' [fixedWidth]='true'></fa-icon>
                        <span class="d-none d-md-inline">View</span>
                        Resume
                    </a>
                </div>
            </div>
            <!--//media-body-->
            <img class="profile-image mb-3 mb-lg-0 ml-lg-5 mr-md-0 mobile-only" src="/assets/images/profile.webp" alt="" loading="lazy">
        </div>
    </div>
</section>
<!--//about-me-section-->
