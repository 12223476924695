import { Component, OnInit } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconsB from '@fortawesome/free-brands-svg-icons'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '../api.service'
import { first } from 'rxjs/operators'
import { Router } from '@angular/router'


@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.styl']
})
export class ResumeComponent implements OnInit {
  public icons = icons
  public iconsB = iconsB
  public auth = false
  private token = ''
  public projects: Array<any> = []
  public loading = true

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {
    this.route.params.pipe(
      first()
    ).subscribe({
      next: (x) => {
        this.token = x.token
      }
    })
  }

  ngOnInit(): void {
    const token = window.sessionStorage.getItem('token')
    if (!!token) {
      this.router.navigate(['resume/' + token])
    }
    this.api.post('cvaccess', { token: this.token }).subscribe({
      next: (x) => {
        this.auth = x.db.permitted
        if (this.auth) {
          window.sessionStorage.setItem('token', this.token)
          this.loadPortfolio()
        }
      },
      error: (e) => {
        this.loading = false
      }
    })
  }

  loadPortfolio(): void {
    this.api.fetch('/projects').subscribe({
      next: (data: any) => {
        this.projects = data.db
      }
    })
  }
}
