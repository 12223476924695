import { Component } from '@angular/core'
import * as iconsB from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.styl']
})
export class NotFoundComponent {

  constructor() { }
  public iconsB = iconsB
}
