import { Component, OnInit } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'loading-portfolio',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.styl']
})
export class LoadingComponent implements OnInit {

  constructor() { }
  public icons = icons

  ngOnInit(): void {
  }

}
