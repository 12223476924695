import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  private urlBase = '/api/'

  private removeFirstSlash(url: string): string {
    if (url[0] === '/') { return url.substring(1) }
    return url
  }

  public fetch(url: string): Observable<any> {
    return this.http.get(this.urlBase + this.removeFirstSlash(url), this.httpOptions)
  }

  public post(url: string, body: any = null): Observable<any> {
    return this.http.post(this.urlBase + this.removeFirstSlash(url), body, this.httpOptions)
  }
}
