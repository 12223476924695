import { Component } from '@angular/core'
import { Router } from '@angular/router'
import * as icons from '@fortawesome/free-solid-svg-icons'
import { ApiService } from '../../api.service'

@Component({
  selector: 'app-featured-section',
  templateUrl: './featured-section.component.html',
  styleUrls: ['./featured-section.component.styl']
})
export class FeaturedSectionComponent {
  public projects: Array<any> = []
  public icons = icons

  constructor(
    private api: ApiService,
    private router: Router,
  ) {
    this.api.fetch('/featuredprojects').subscribe({
      next: (data: any) => {
        this.projects = data.db
      },
      error: () => {
        this.router.navigate(['offline'])
      }
    })
  }
}
