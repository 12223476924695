import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { first } from 'rxjs/operators'
import { ApiService } from '../api.service'

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.styl']
})
export class LegalComponent implements OnInit {
  private legalSlug: any = undefined
  public legal: any = {}

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private titleService: Title,
  ) {
    this.route.params.pipe(
      first()
    ).subscribe({
      next: (x) => {
        this.legalSlug = x.legalSlug
      }
    })
  }

  ngOnInit(): void {
    this.api.post('legal', { legalSlug: this.legalSlug }).subscribe({
      next: (x) => {
        this.legal = x.db
      },
      complete: () => {
        this.titleService.setTitle(`${this.legal.name} - Gareth Johnstone`)
      },
      error: () => {
        this.router.navigate(['offline'])
      }
    })
  }

}
