import { Component, OnInit } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconsB from '@fortawesome/free-brands-svg-icons'
import { MenuService } from '../menu.service'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.styl']
})
export class MenuComponent implements OnInit {
  public icons = icons
  public iconsB = iconsB
  public menuItems = [
    {
      title: 'About Me',
      url: '/home',
      icon: this.icons.faUser
    },
    {
      title: 'Portfolio',
      url: '/portfolio',
      icon: this.icons.faLaptopCode
    },
    {
      title: 'Resume',
      url: '/resume',
      icon: this.icons.faFileAlt
    },
    {
      title: 'Contact Me',
      url: '/contact',
      icon: this.icons.faPaperPlane
    }
  ]

  constructor(
    public menu: MenuService
  ) { }

  ngOnInit(): void {
  }

}
