import { Component } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import Swal from 'sweetalert2'
import { ApiService } from '../api.service'
import * as icons from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.styl']
})
export class ContactComponent {
  public contactMeForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    company: new FormControl(''),
    message: new FormControl('')
  })
  public icons = icons
  public loading = false

  constructor(
    private api: ApiService,
    private $gaService: GoogleAnalyticsService
  ) { }

  get f(): any { return this.contactMeForm.controls }

  submit(): any {
    if (!this.contactMeForm.valid) {
      return Swal.fire({
        title: 'Form Invalid',
        text: 'You have missing fields.',
        icon: 'error'
      })
    }

    this.loading = true

    const form = {
      name: this.f.name.value,
      email: this.f.email.value,
      company: this.f.company.value,
      message: this.f.message.value
    }

    this.api.post('contact', { formPost: form }).subscribe({
      next: (x) => {
        if (x.db) {
          this.contactMeForm.reset()
          this.$gaService.event('submit', 'contactMeForm', 'success')
          Swal.fire('Thanks', 'I will be in touch', 'success')
        } else {
          this.$gaService.event('submit', 'contactMeForm', 'fail')
          Swal.fire('oops', 'Something went wrong :( - I have been informed of this error.', 'error')
        }
      },
      complete: () => {
        this.loading = false
      }
    })
  }

}
