import { Component, OnInit } from '@angular/core'
import * as icons from '@fortawesome/free-solid-svg-icons'
import * as iconsB from '@fortawesome/free-brands-svg-icons'



@Component({
  selector: 'app-overview-section',
  templateUrl: './overview-section.component.html',
  styleUrls: ['./overview-section.component.styl']
})
export class OverviewSectionComponent implements OnInit {
  public icons = icons
  public iconsB = iconsB

  constructor() { }

  ngOnInit(): void {
  }

}
