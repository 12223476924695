<section class="featured-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-5">Featured Projects</h2>

        <loading-portfolio *ngIf="projects.length < 1"></loading-portfolio>

        <div class="row">
            <div class="col-md-6 mb-5" *ngFor="let project of projects">
                <div class="card project-card">
                    <div class="row no-gutters">
                        <div class="col-lg-4 card-img-holder">
                            <img [src]="project.image" class="card-img" alt="image" loading="lazy">
                        </div>
                        <div class="col-lg-8">
                            <div class="card-body">
                                <h5 class="card-title">
                                    <a [routerLink]="['/portfolio/'+project.slug]" class="theme-link">
                                        {{project.title}}
                                    </a>
                                </h5>
                                <p class="card-text" [innerHTML]="project.summary"></p>
                                <p class="card-text" *ngIf="project.client">
                                    <small class="text-muted">Client: {{project.client}}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="link-mask">
                        <a class="link-mask-link" [routerLink]="['/portfolio/'+project.slug]"></a>
                        <div class="link-mask-text">
                            <a class="btn btn-secondary" [routerLink]="['/portfolio/'+project.slug]">
                                <fa-icon [icon]='icons.faEye' [fixedWidth]='true'></fa-icon>
                                View Project
                            </a>
                        </div>
                    </div>
                    <!--//link-mask-->
                </div>
                <!--//card-->
            </div>
        </div>
        <!--//row-->
        <div class="text-center py-3">
            <a [routerLink]="['/portfolio']" class="btn btn-primary">
                <fa-icon [icon]='icons.faArrowAltCircleRight' [fixedWidth]='true'></fa-icon>
                View Portfolio
            </a>
        </div>

    </div>
    <!--//container-->
</section>
<!--//featured-section-->