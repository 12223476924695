import { Component } from '@angular/core'
import * as iconsB from '@fortawesome/free-brands-svg-icons'


@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.styl']
})
export class OfflineComponent {
  public iconsB = iconsB

  constructor() { }

}
