import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ContactComponent } from './contact/contact.component'
import { HomeComponent } from './home/home.component'
import { LegalComponent } from './legal/legal.component'
import { NotFoundComponent } from './not-found/not-found.component'
import { OfflineComponent } from './offline/offline.component'
import { PortfolioComponent } from './portfolio/portfolio.component'
import { ProjectComponent } from './project/project.component'
import { ResumeComponent } from './resume/resume.component'


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Gareth Johnstone',
      description: 'Gareth Johnstone Portfolio',
      image: '/assets/images/profile.webp'
    }
  },
  {
    path: 'offline',
    component: OfflineComponent,
    data: {
      title: 'Something Went Wrong - Gareth Johnstone',
      description: 'Something went wrong while loading the website.',
      image: '/assets/images/profile.webp'

    }
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
    data: {
      title: 'Portfolio - Gareth Johnstone',
      description: 'Projects I\'ve built.',
      image: '/assets/images/profile.webp'
    }

  },
  {
    path: 'resume',
    component: ResumeComponent,
    data: {
      title: 'Resume - Gareth Johnstone',
      description: 'My Resume.',
      image: '/assets/images/profile.webp'
    }

  },
  {
    path: 'resume/:token',
    component: ResumeComponent,
    data: {
      title: 'Resume - Gareth Johnstone',
      description: 'My Resume.',
      image: '/assets/images/profile.webp'
    }
  },
  {
    path: 'project/:projectSlug',
    component: ProjectComponent,
    data: {
      title: 'Project - Gareth Johnstone',
      description: 'Project',
      image: '/assets/images/profile.webp'
    }
  },
  {
    path: 'portfolio/:projectSlug',
    component: ProjectComponent,
    data: {
      title: 'Project - Gareth Johnstone',
      description: 'Project',
      image: '/assets/images/profile.webp'
    }
  },
  {
    path: 'legal/:legalSlug',
    component: LegalComponent,
    data: {
      title: 'Legal - Gareth Johnstone',
      description: 'Legal',
      image: '/assets/images/profile.webp'
    }
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: {
      title: 'Contact Me - Gareth Johnstone',
      description: 'Contact Me',
      image: '/assets/images/profile.webp'
    }
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '404',
    component: NotFoundComponent,
    data: {
      title: '404 Not Found - Gareth Johnstone',
      description: '404',
      image: '/assets/images/profile.webp'
    }
  },
  { path: '**', redirectTo: '/404' }

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
