<span *ngIf="auth else noAuth">
    <section class="cta-section theme-bg-light py-5">
        <div class="container text-center single-col-max-width">
            <h2 class="heading mb-3">Resume</h2>
            <a class="btn btn-primary" href="" target="_blank" *ngIf="false">
                <fa-icon class='mr-2' [icon]='icons.faFilePdf' [fixedWidth]='true'></fa-icon>
                Download PDF Version
            </a>
        </div>
        <!--//container-->
    </section>
    <div class="container px-3 px-lg-5">
        <article class="resume-wrapper mx-auto theme-bg-light p-5 mb-5 my-5 shadow-lg">

            <div class="resume-header">
                <div class="row align-items-center">
                    <div class="resume-title col-12 col-md-6 col-lg-8 col-xl-9">
                        <h2 class="resume-name mb-0 text-uppercase">Gareth Johnstone</h2>
                        <div class="resume-tagline mb-3 mb-md-0">Web Development Manager</div>
                    </div>
                    <!--//resume-title-->
                    <div class="resume-contact col-12 col-md-6 col-lg-4 col-xl-3">
                        <ul class="list-unstyled mb-0">
                            <li class="mb-2">
                                <fa-icon class='mr-2' [icon]='icons.faPhoneSquare' [size]="'lg'" [fixedWidth]='true'>
                                </fa-icon>
                                <a class="resume-link" href="tel:07592907631">07592 90731</a>
                            </li>
                            <li class="mb-2">
                                <fa-icon class='mr-2' [icon]='icons.faEnvelopeSquare' [size]="'lg'" [fixedWidth]='true'>
                                </fa-icon>
                                <a class="resume-link"
                                    href="mailto:me@garethjohnstone.co.uk">me@garethjohnstone.co.uk</a>
                            </li>
                            <li class="mb-2">
                                <fa-icon class='mr-2' [icon]='icons.faGlobe' [size]="'lg'" [fixedWidth]='true'>
                                </fa-icon>
                                <a class="resume-link"
                                    href="https://garethjohnstone.co.uk/">www.garethjohnstone.co.uk</a>
                            </li>
                            <li class="mb-0">
                                <fa-icon class='mr-2' [icon]='icons.faMapMarkerAlt' [size]="'lg'" [fixedWidth]='true'>
                                </fa-icon>Scottish Borders
                            </li>
                        </ul>
                    </div>
                    <!--//resume-contact-->
                </div>
                <!--//row-->

            </div>
            <!--//resume-header-->
            <hr>
            <div class="resume-intro py-3">
                <div class="media flex-column flex-md-row align-items-center">
                    <img class="resume-profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto"
                        src="/assets/images/profile.webp" alt="image" loading="lazy">
                    <div class="media-body text-left">
                        <p class="mb-0">
                            I am an enthusiastic individual that enjoys a challenge. I have excellent computing skills
                            including creative and
                            technical ability. I have developed many web applications using multiple web based languages
                            including:
                        </p>
                        <ul>
                            <li>PHP (including Zend Framework, Facebook/Twitter/YouTube/Soundcloud/Google APIS)</li>
                            <li>MySQL</li>
                            <li>JavaScript (including popular frameworks: jQuery, MooTools)</li>
                            <li>HTML/5 & CSS/3</li>
                        </ul>
                        <p class="mb-0">
                            Over the course of learning all these languages I have been using many Content Management
                            Systems including Wordpress as well as building my own to use for my previous clients.
                            Additionally, I have built an Ecommerce website to sell digital downloads. I have worked on
                            the
                            popular forum software called Invision Power Board creating external applications and
                            plugins
                            for it. Working with the Linux systems is something that I have always been good at, setting
                            them up for a web environment and to use custom scripts.
                        </p>
                        <p class="mb-0">
                            I am confident using Photoshop, Dreamweaver and other Adobe Creative Suite products. I would
                            be
                            keen to have the opportunity to learn and develop new skills as well as my work experience.
                        </p>
                    </div>
                    <!--//media-body-->
                </div>
            </div>
            <!--//resume-intro-->
            <hr>
            <div class="resume-body">
                <div class="row">
                    <div class="resume-main col-12 col-lg-8 col-xl-9 pr-0 pr-lg-5">
                        <section class="work-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">
                                Work Experiences
                            </h3>

                            <div class="item mb-3">
                                <div class="item-heading row align-items-center mb-2">
                                    <h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
                                        Web Developer, Software Systems Developer &amp; IT Manager
                                    </h4>
                                    <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                                        IQX Limited | 2015 - Present</div>
                                </div>
                                <div class="item-content">
                                    <p>Current Role </p>
                                </div>
                            </div>
                            <!--//item-->
                            <div class="item mb-3">
                                <div class="item-heading row align-items-center mb-2">
                                    <h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
                                        Ecommerce SEO Analyst
                                    </h4>
                                    <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                                        Edinburgh Woollen Mill | 2014 - 2015
                                    </div>
                                </div>
                                <div class="item-content">
                                    <p>
                                        I was employed by the Edinburgh Woollen Mill, my job was to ensure product
                                        listings
                                        are fully optimized for search
                                        engines as well as adding new products to their online store and optimizing
                                        them.
                                    </p>
                                </div>
                            </div>
                            <!--//item-->
                            <div class="item mb-3">
                                <div class="item-heading row align-items-center mb-2">
                                    <h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Web Developer</h4>
                                    <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                                        Edinburgh Woollen Mill | 2014 - 2015
                                    </div>

                                </div>
                                <div class="item-content">
                                    <p>My role at the Edinburgh Woollen mill was adapted to suit my skill set, I also
                                        develop tools and addons for the
                                        Edinburgh Woollen mill website as well as developing for the Edinburgh Woollen
                                        Mill
                                        Group (Jane Norman, Peacocks and
                                        Ponden Home).</p>
                                </div>
                            </div>
                            <!--//item-->
                        </section>
                        <!--//work-section-->

                        <section class="project-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">Projects</h3>
                            <div class="item mb-3">
                                <div class="item-heading row align-items-center mb-2">
                                    <h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
                                        Web Hosting
                                    </h4>
                                    <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">
                                        Personal Project
                                    </div>
                                </div>
                                <div class="item-content">
                                    <p>Since I started developing web applications I needed a powerful enough server to
                                        host
                                        them all, as I had some space
                                        left, I decided to setup a part of it to sell web hosting to previous clients I
                                        had
                                        done website for. This has allowed
                                        to me to gain better knowledge of the linux system and the setup of a LAMP
                                        stack.
                                    </p>
                                </div>
                            </div>
                            <!--//item-->
                            <div class="item">
                                <div class="item-heading row align-items-center mb-2">
                                    <h4 class="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">
                                        Client Based Projects
                                    </h4>
                                    <div class="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">

                                    </div>

                                </div>
                                <div class="item-content table-responsive">
                                    <table class="table table-striped ">
                                        <thead>
                                            <th>Project</th>
                                            <th>Summary</th>
                                            <th>&nbsp;</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let project of projects">
                                                <td>{{project.title}}</td>
                                                <td [innerHTML]='project.summary'></td>
                                                <td>
                                                    <a class="btn btn-sm btn-outline btn-secondary"
                                                        [routerLink]="['/portfolio/' + project.slug]">view</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--//item-->
                        </section>
                        <!--//project-section-->
                    </div>
                    <!--//resume-main-->
                    <aside class="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4">
                        <section class="skills-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">Skills</h3>
                            <div class="item">
                                <h4 class="item-title">Technical</h4>
                                <ul class="list-unstyled resume-skills-list">
                                    <li class="mb-2">JavaScript/Angular/AngularJS/Vue</li>
                                    <li class="mb-2">PHP</li>
                                    <li class="mb-2">Node.js/Express</li>
                                    <li class="mb-2">SQLAnywhere/MySQL/ PostgreSQL/MongoDB</li>
                                    <li class="mb-2">Object-oriented design</li>
                                    <li class="mb-2">Design and implement database structures</li>
                                    <li>Lead and deliver complex software systems</li>
                                </ul>
                            </div>
                            <!--//item-->
                            <div class="item">
                                <h4 class="item-title">Professional</h4>
                                <ul class="list-unstyled resume-skills-list">
                                    <li class="mb-2">Effective communication</li>
                                    <li class="mb-2">Team player
                                    <li>
                                    <li class="mb-2">Strong problem solver</li>
                                    <li>Good time management</li>
                                </ul>
                            </div>
                            <!--//item-->
                        </section>
                        <!--//skills-section-->
                        <section class="education-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">Education</h3>
                            <ul class="list-unstyled resume-education-list">
                                <li class="mb-3">
                                    <div class="resume-degree font-weight-bold">
                                        HNC Interactive Media
                                    </div>
                                    <div class="resume-degree-org text-muted">
                                        Borders College
                                    </div>
                                </li>
                                <li>
                                    <div class="resume-degree font-weight-bold">
                                        NC Digital Media
                                    </div>
                                    <div class="resume-degree-org text-muted">
                                        Borders College
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <!--//education-section-->
                        <section class="education-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">Awards</h3>
                            <ul class="list-unstyled resume-awards-list">
                                <li class="mb-3">
                                    <div class="font-weight-bold">
                                        Best Further Education Student
                                    </div>
                                    <div class="text-muted">Borders College (2012)</div>
                                </li>
                            </ul>
                        </section>
                        <!--//education-section-->
                        <section class="skills-section py-3">
                            <h3 class="text-uppercase resume-section-heading mb-4">Languages</h3>
                            <ul class="list-unstyled resume-lang-list">
                                <li class="mb-2">
                                    English <span class="text-muted">(Native)</span>
                                </li>
                                <li>
                                    Profanity <span class="text-muted">(Fluient)</span>
                                </li>
                            </ul>
                        </section>
                        <!--//certificates-section-->
                    </aside>
                    <!--//resume-aside-->
                </div>
                <!--//row-->
            </div>
            <!--//resume-body-->
            <hr>
            <div class="resume-footer text-center">
                <ul class="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
                    <li class="list-inline-item mb-lg-0 mr-3">
                        <a class="resume-link" href="https://github.com/gaza1994" target=_blank>
                            <fa-icon class='mr-2' [icon]='iconsB.faGithubSquare' [size]="'2x'" [fixedWidth]='true'
                                [transform]="'down-4'"></fa-icon>
                            <span class="d-none d-lg-inline-block text-muted">
                                github.com/gaza1994
                            </span>
                        </a>
                    </li>
                    <li class="list-inline-item mb-lg-0 mr-3">
                        <a class="resume-link" href="https://linkedin.com/in/gazajohnstone" target=_blank>
                            <fa-icon class='mr-2' [icon]='iconsB.faLinkedin' [size]="'2x'" [fixedWidth]='true'
                                [transform]="'down-4'"></fa-icon>
                            <span class="d-none d-lg-inline-block text-muted">
                                linkedin.com/in/gazajohnstone</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!--//resume-footer-->
        </article>

    </div>
    <!--//container-->
</span>

<ng-template #noAuth>
    <section *ngIf="loading" class='text-center'>
        <fa-icon class='mr-2' [icon]='icons.faSpinner' [size]='"4x"' [spin]='true' [fixedWidth]='true'></fa-icon>
    </section>
    <app-resume-request-access *ngIf="!loading"></app-resume-request-access>
</ng-template>