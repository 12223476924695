import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { filter } from 'rxjs/operators'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
        this.getChild(this.activatedRoute).data.subscribe((data: any) => {
          this.titleService.setTitle(data.title)

          this.metaService.updateTag({ property: 'og:title', content: data.title })
          this.metaService.updateTag({ property: 'og:description', content: data.description })
          this.metaService.updateTag({ property: 'og:image', content: data.image })
          this.metaService.updateTag({ property: 'og:url', content: window.location.toString() })
          this.metaService.updateTag({ property: 'og:image:alt', content: data.title })

          this.metaService.updateTag({ name: 'twitter:title', content: data.title })
          this.metaService.updateTag({ name: 'twitter:description', content: data.description })
          this.metaService.updateTag({ name: 'twitter:image', content: 'https://garethjohnstone.co.uk' + data.image })
          this.metaService.updateTag({ name: 'twitter:url', content: window.location.toString() })
          this.metaService.updateTag({ name: 'twitter:image:alt', content: data.title })
        })
      })
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild)
    } else {
      return activatedRoute
    }
  }
}
