<section class="overview-section p-3 p-lg-5">
    <div class="container">
        <h2 class="section-title font-weight-bold mb-3">What I do</h2>
        <div class="section-intro mb-5">
            I have more than 10 years' experience building software for clients all over the world. Below is a quick
            overview of my main technical skill sets and technologies I use.
            Want to find out more about my experience? Check out my
            <a [routerLink]="['/resume']">resume</a>
            and
            <a [routerLink]="['/portfolio']">portfolio</a>.
        </div>
        <div class="row">
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faJsSquare' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Vanilla JavaScript</h3>
                    <div class="item-desc">

                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faAngular' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faVuejs' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Angular &amp; Vue</h3>
                    <div class="item-desc">
                        AngularJS, Anagular 2+, VueJS
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->

            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faNodeJs' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Node.js</h3>
                    <div class="item-desc">
                        NodeJS, ExpressJS
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->

            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faPython' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Python</h3>
                    <div class="item-desc">
                        Creating little utility scripts, but mostly been used while playing with Neural Networks
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faPhp' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faWordpress' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">PHP &amp; Wordpress</h3>
                    <div class="item-desc">
                        PHP is where my love of programming started.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faDocker' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Docker</h3>
                    <div class="item-desc">
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faNpm' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faGulp' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">npm &amp; Gulp</h3>
                    <div class="item-desc">

                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faHtml5' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faCss3' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">HTML &amp; CSS &amp; Bootstrap</h3>
                    <div class="item-desc">

                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faSass' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faLess' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Sass &amp; LESS &amp; Stylus</h3>
                    <div class="item-desc">
                        Sass, Less and Stylus (font awesome don't have an icon for it)
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='iconsB.faJava' [fixedWidth]='true'></fa-icon>
                        <fa-icon [icon]='iconsB.faAndroid' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Java, Kotlin</h3>
                    <div class="item-desc">
                        Android Apps
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='icons.faDesktop' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">vb.net, C++, C#</h3>
                    <div class="item-desc">
                        Mostly VB.net, but can navigate my way around C++ &amp; C# code.
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon">
                        <fa-icon [icon]='icons.faDatabase' [fixedWidth]='true'></fa-icon>
                    </div>
                    <h3 class="item-title">Databases</h3>
                    <div class="item-desc">
                        MySQL/MariaDB, SQLAnywhere, MongoDB, PostgreSQL
                    </div>
                </div>
                <!--//item-inner-->
            </div>
            <!--//item-->
        </div>
        <!--//row-->
    </div>
    <!--//container-->
</section>